// TeamMemberDetail.js

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./TeamMemberDetail.css";

function TeamMemberDetail() {
  useEffect(() => {
    // Hide overflow
    document.body.style.overflowY = 'hidden';
  
    // Scroll to top
    window.scrollTo(0, 0);
  
    // Restore overflow
    setTimeout(() => {
      document.body.style.overflowY = 'auto';
    }, 50);
  }, []);
  

  const { memberName } = useParams();

  const memberDetail = [
    {
      name: "Sebastian Kochan",
      title: "Founder & Chief Executive Officer",
      description: `Building upon his experience in entrepreneurship and sustainability, Sebastian relocated from London (UK) to La Paz (Bolivia) in March 2023, in pursuit of innovative socio-environmental projects.

      Subsequently, he founded Sonoluminessence, driven by clear objectives in environmental regeneration, and its connection to social problems. Sebastian leads the organisation's corporate objectives and reinvestment thesis, with the overarching aim to create projects with strong decarbonisation potential and to end human trafficking in our generation.
      
      After graduating from the London School of Economics and Political Science (LSE) in 2020, Sebastian worked for two years as the Executive Assistant to Mark Gainsborough, the former President of New Energies at Shell, where Mark led the creation of Shell’s Global New Energies Division. 
      
      During these two years, Sebastian worked under world leading CEO’s spearheading the Energy Transition, in fields such as; critical-raw materials, electrification, hydrogen, sustainability consulting, UAVs and much more.
      
      Sebastian majored in International History from the LSE, whilst also studying Sustainable Development, Linguistics, International Relations and Philosophy. 
      
      He presided as the Head of Events for LSE International Development Society (InterDev), growing the society's membership, as well as conceptualising and delivering the inaugural London Development Symposium (LDS); the largest student-led International Development conference globally in 2020. 
      `,
      imageUrl: "/images/sebastian.png",
    },
    {
      name: "Yusuf Rafique",
      title: "Founder",
      description: `Yusuf is a super-connector as well as a versatile professional with a diverse background spanning various domains. He has integrated his expertise in fast moving consumer goods distribution with food technology, and blockchain; and has gained professional experience across seven countries, including the UK, India, Hong Kong, Canada, Germany, Switzerland, and Mauritius.Yusuf’s academic foundation was furnished at the London School of Economics and Political Science (LSE). 

      Within HALT Group, a family enterprise, Yusuf advances a century-long legacy. The group oversees ventures in FMCG, wholesale trade, mobile technology, and the automotive sector. Currently, Yusuf focuses on the rice division as the official distributor and C&F for India Gate Basmati Rice in South Karnataka. 
      
      Yusuf’s academic foundation was furnished at the London School of Economics and Political Science (LSE). Whilst at LSE, his engagement with the Future Impact Initiative was coupled with a distinguished role on the Trustee Board, where he was voted to represent over 12,000 LSE students. Moreover, Yusuf secured the second position in the election for General Secretary of the LSE Student Union in 2020, evidencing remarkable leadership, organisational acumen and community engagement capabilities.
      
      Yusuf holds a Bachelor's degree in Industrial Engineering from City University of Hong Kong, in addition to a dual Master's degree from the LSE, specialising in Management and CEMS from St. Gallen University, Switzerland.`,
      imageUrl: "/images/yusuf.png",
    },
    {
      name: "Javier Guachalla",
      title: "Founder",
      description: `Javier is a leading Bolivian psychoanalyst, with an extensive track record in clinical areas; namely, psychoanalysis, oncology, emergencies, and work within the psychiatry and children’s burns wards in Mexico City spanning the last 25 years.

      During this period, Javier gave classes in various universities up to a PhD level; in topics such as: Freudian models; Freudian & Lacanian Clinical methods; Lacanian Mathematics; Lacanian language models; Social psychology; High governance in enterprise; Methods of thinking and creativity for governancep and enterprises; Personality and criminal psychology, and beyond.
      
      Furthermore, Javier simultaneously taught English within the Mexican stock market for an eight year period. 
      
      He is the founder of nonprofits Alethosfera and Inmarscecible; the former dedicated to expanding complex educational access, and the latter to combatting human sexual torture, sexual crimes against children, slavery, organ trafficking and all disappearances.`,
      imageUrl: "/images/javier.png",
    },
    {
      name: "Gonzalo Eduardo Salas Enao",
      title: "Core Team",
      description: `Gonzalo is a widely renowned Bolivian expert in social and environmental endeavours related to mining, health, youth and gender equality - with a strong specialisation in rural community development spanning over 21 years. 

      He has worked extensively with the national Bolivian Mining Corporation (COMIBOL), as well as UNFPA, SMN JH Piego, Doctors without Borders, USAID OMS/OPS - realising high level socio-economic KPIs to enhance the quality of life of mining and farming communities in every region of Bolivia.
      
      Gonzalo brings a wealth of interdisciplinary expertise, with which has built cross-sector collaboration between local villages, environmental engineers, civil engineers, geologists, mining geographers and economists to fight against mining contamination and community injustices.
      
      Gonzalo has a MSc in environmental impact evaluation, in addition to a BA in conflict negotiation, which he has utilised to teach protection methods for gender equality, to enhance awareness for women and children about sexual slavery. `,
      imageUrl: "/images/gonzalo.png",
    },
    {
      name: "Ishaaq Shafi",
      title: "Core Team",
      description: `Meet Ishaaq, our Chief Innovation Officer, a visionary leader with a diverse background and a passion for catalysing transformative change. With time spent studying at both Wharton and LSE, Ishaaq brings a distinctive blend of strategic insight and entrepreneurial spirit to our team.

      Ishaaq's journey spans continents and industries, honing a dynamic skill set. From pioneering startups across diverse fields like peer-to-peer rental and food tech, to orchestrating impactful cross-border projects, his global perspective is invaluable. Living in London, Singapore, and Philadelphia has enriched his understanding of diverse markets and cultures.
      
      With a proven track record of effectively managing remote teams, Ishaaq excels in fostering collaboration across distances. His leadership extends to the successful execution of international social impact initiatives, showcasing his dedication to driving positive change.
      
      Ishaaq's recent focus on harnessing the potential of Artificial Intelligence to streamline repetitive tasks underscores his forward-looking approach. As our Chief Innovation Officer, he remains at the helm of steering our company's evolution at the intersection of technology and strategy. His unwavering commitment to catalytic leadership stands as a testament to his dedication to driving impactful change.`,
      imageUrl: "/images/ishaaq.png",
    },
    {
      name: "German Guachalla",
      title: "Core Team",
      description: `Germán Guachalla is a dynamic Project Manager and Consultant with a multi-faceted career that spans over 20 years, covering a diverse range of industries including mining, telecommunications, information technology, and safety (QHSE). He specialises in implementing cutting-edge safety and intelligent solutions that redefine industry standards.
      With a track record of delivering world-class projects across Asia, Africa, and the Americas, Germán's boots-on-the-ground approach sets him apart. His ability to seamlessly navigate different cultures, gained through a lifetime of international exposure, enables him to connect with individuals at all levels, from front-line workers to top-tier management.`,
      imageUrl: "/images/german.png",
    },
  ];
  const member = memberDetail.find(
    (m) => m.name === decodeURIComponent(memberName)
  );

  const text = member.description;
  const lines = text.trim().split("\n");
  const halfLength = Math.ceil((lines.length * 5) / 8);
  const firstHalf = lines.slice(0, halfLength).join("\n");
  const secondHalf = lines.slice(halfLength).join("\n");
  // Assuming you have an API or a method to fetch the details of a team member based on their name:

  if (!member) {
    return <div>Member not found</div>;
  }

  return (
    <div className="member-detail">
      <div className="member-header"></div>
      <div className="description-container">
        <div className="description-left">
          <h2 style={{ color: "#160e2b" }}>{member.name}</h2>
          <h4 style={{ color: "#e18602" }}> {member.title}</h4>
          <br />
          {firstHalf.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
        <div className="description-right">
          <div className="image-container">
            <img src={member.imageUrl} alt={member.name} />
          </div>
          {secondHalf.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        
        </div>
        <br />
          <br />
          <br />
      </div>
      <br />
          <br />
          <br />
    </div>
  );
}

export default TeamMemberDetail;
