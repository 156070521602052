import React,{useEffect}from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes ,useLocation} from 'react-router-dom';
import TeamMemberDetail from './TeamMemberDetail';
import HomePage from './HomePage';
import ScrollToHash from './ScrollToHash'; // Adjust the import path accordingly

function Header() {
  
  const location = useLocation();
  const isTeamDetailPage = location.pathname.includes('/team/');
  // Determine if it's the home page or team member detail page
  const isHomePage = location.pathname === "/";
  const navLinkClass = isHomePage ? 'nav-link-home' : 'nav-link-team';
  
  return (
    <header className="App-header">
      <div className="logo">
        <img src="/images/logo-small.png" alt="Logo" />
      </div>
      <nav>
        <ul className="nav-links">
          <li>
            <a  className={`nav-link-i ${navLinkClass}`}  href={isTeamDetailPage ? "/" : "#home"}>
              HOME
            </a>
          </li>
          <li>
            <a  className={`nav-link-i ${navLinkClass}`}   href={isTeamDetailPage ? "/#about" : "#about"}>
              ABOUT
            </a>
          </li>
          <li>
            <a  className={`nav-link-i ${navLinkClass}`}  href={isTeamDetailPage ? "/#team" : "#team"}>
              TEAM
            </a>
          </li>
          <li>
            <a   className={`nav-link-i ${navLinkClass}`}  href={isTeamDetailPage ? "/#contact" : "#contact"}>
              CONTACT
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}


function App() {

  return (
    <Router>
    <div className="App">
    <ScrollToHash />

      
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/team/:memberName" element={<TeamMemberDetail />} />
      </Routes>
    <Header/>

      <footer className="footer">
        <div className="footer-column logo-column">
          <img
            src="/images/logo-big.png"
            alt="Company Logo"
            className="logo"
          />
        </div>
        <br />
        <div className="footer-column info-column">
          <div className="title-row">
            <p>Contact </p>
          </div>
          <div className="info-row">
            <div className="info-section">
              Bolivia: +59 178886070
              <br />
              India: +91 97402 78478
              <br />
              Mexico: +52 55435 61641
              <br />
              UK: +44 77344 99942
              <br />
            </div>
            <br />
            <div className="info-section">
              <p>
                EMAIL ADDRESS: <br />
                Info@sonoluminessence.com
              </p>
            </div>
            <div className="info-section">
              <p>
                ADDRESS: <br />1 Cumberland Park, London, W3 6SY, United
                Kingdom
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </Router>
  );
}

export default App;
