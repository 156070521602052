// HomePage.js
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { Parallax } from "react-scroll-parallax";

function HomePage() {
  // Move your main content from App.js here
  const teamMembers = [
    {
      name: "Sebastian Kochan",
      title: "Founder & Chief Executive Officer",
      imageUrl: "/images/sebastian.png",
    },
    {
      name: "Yusuf Rafique",
      title: "Founder",
      imageUrl: "/images/yusuf.png",
    },
    {
      name: "Javier Guachalla",
      title: "Founder",
      imageUrl: "/images/javier.png",
    },
    {
      name: "Gonzalo Eduardo Salas Enao",
      title: "Core Team",
      imageUrl: "/images/gonzalo.png",
    },
    {
      name: "Ishaaq Shafi",
      title: "Core Team",
      imageUrl: "/images/ishaaq.png",
    },
    {
      name: "German Guachalla",
      title: "Core Team",
      imageUrl: "/images/german.png",
    },
  ];

  return (
    <div className="App">
      <section id="home" className="home-section">
      
        <div className="center-image">
          <img src="/images/logo-big.png" alt="Centered Main" />
        </div>
      </section>
      <section id="about" className="about-section">
        <div className="about-left-column">
          <h1 style={{ color: "#9aacea" }}>OUR VISION</h1>
          <br />
          <p style={{ color: "white" }}>
            We supply Critical Raw Materials (CRMs) to catalyse the energy
            transition. Through our presence in the UK, India, Chile and
            Bolivia, we are currently looking to partner with players throughout
            the EV vertical value chain to supply CRMs and Rare Earth metals for
            the EV Battery Sector.
            <br /> <br />
            We are unshakeable believers in a Just Energy Transition, that
            simultaneously benefits communities both in EV consumer countries,
            and also in the countries that provide the minerals to power the EV
            revolution abroad. Subsequently, our reinvestment thesis is to
            utilise our profits to cultivate projects throughout Latin America
            with high decarbonisation and social value addition, in order to
            benefit the essential stakeholders currently being left behind by
            the EV revolution, whilst minimising volatility in these complex
            environments.
          </p>
          <br />
        {/*}   <button className="explore-button">EXPLORE</button>*/}
        </div>
        <div className="about-right-column">
            <img
              src="/images/earth1.png"
              alt="Earth 1"
              className="earth-image"
            />
        </div>{" "}
      </section>
      <section id="lithium" className="lithium-section">
        <section className="lithium-two-columns-section">
          <div className="lithium-left-column"></div>
          <div className="lithium-right-column">
            <h2>LITHIUM: A CRITICAL RAW MATERIAL</h2>
            <br />
            <p>
              'Transition-Critical Materials’ are the materials that will have
              to serve as critical, and therefore transition- enabling inputs,
              for the low-carbon technologies needed to bring about the energy
              transition.
            </p> 
           {/*button className="lithium-learnmore-button">LEARN MORE</button> */}
          </div>
        </section>
      </section>
      <section id="team" className="team-section">
        <div className="team-header">
        <h1 style={{ color: "#9aacea", textAlign: "center" }}>TEAM</h1>
        <br />
        <div className="centered-text">
          {" "}
          We are a multidisciplinary team of believers, innovators and change
          makers.
          <br /> <br />
          Our team of experts, endeavour to generate maximum value for the
          planet, the communities in which we work; the non-profit organisations
          which we support; all stakeholders throughout our value chain, as well
          as future generations.
        </div>
        </div>
      
        <div className="grid-container team-body">
          {teamMembers.map((member, index) => (
            <Link
              to={`/team/${encodeURIComponent(member.name)}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="grid-item">
                <img src={member.imageUrl} alt={member.name} />
                <h3>{member.name}</h3>
                <p>{member.title}</p>
              </div>
            </Link>
          ))}
        </div>
      </section>
      <section id="sustainability" className="sustainability-section">
        <section className="sustainability-two-columns-section">
          <div className="sustainability-left-column">
            <h2>Sustainability</h2>
          </div>
          <div className="sustainability-right-column">
            <p>
              The hidden environmental costs of Lithium production puts battery
              manufacturing at odds with environmental regulations and broader
              socio-environmental and socio- economic goals.
            </p>
{/* <button className="explore-button">LEARN MORE</button> */}
</div>
        </section>
        {/* Your content here */}
      </section>
      <section id="contact" className="contact-section">
        <section className="contact-two-columns-section">
          <div className="contact-left-column">
            <img
              src="/images/earth2.png"
              alt="Earth 1"
              className="earth-image-2"
            />
        </div>

          <div className="contact-right-column">
            <p>
              Climate change is not a 'problem' waiting for a 'solution'. It is
              an environmental, cultural and political phenomenon that is
              reshaping the way we think about ourselves, about our societies
              and about humanity's place on Earth. <br />
              <br />
              Prof. Mike Hulme, MSc PhD.
            </p>
          </div>
        </section>
      </section>{" "}
      {/* End of contact section */}
      {/* Start of footer section */}
      <section id="footer-section"></section> {/* End of footer section */}
    </div>
  );
}

export default HomePage;
